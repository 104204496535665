<template>
  <div class="qi">
    <div class="itemBox" v-if="showStatus == 2">
      <div class="itemName">
        <el-divider>
          <div class="dividerFont">
            <span>班组信息</span>
            <div
              style="width: 60px; height: 2px; background-color: #029aff"
            ></div>
          </div>
        </el-divider>
      </div>
      <div class="essentialInformation">
        <div class="title">
          <div class="blueBgc">
            <i
              class="iconfont icon-jibenxinxi1"
              style="font-size: 20px; color: #fff"
            ></i>
          </div>
          <b>班组长信息</b>
          <el-divider></el-divider>
          <div class="edit-info" @click="editInfo">修改信息</div>
        </div>
        <div class="formItem">
          <div class="personalInfo">
            <b>{{ orgInfo.name }}</b>
            <ul>
              <li>
                <span class="fontColor6">班组人数</span>
                <span class="fontColor6">{{
                  orgInfo.orgCode ? orgInfo.orgCode : ""
                }}</span>
              </li>
              <li>
                <span class="fontColor6">性别</span>
                <span class="fontColor6">{{
                  orgInfo.sex ? (orgInfo.sex == "1" ? "男" : "女") : ""
                }}</span>
              </li>
              <li>
                <span class="fontColor6">城市</span>
                <span class="fontColor6">{{
                  orgInfo.city ? showCity(orgInfo.city) : ""
                }}</span>
              </li>
              <li>
                <span class="fontColor6">职位</span>
                <!-- <span class="fontColor6">{{
                  orgInfo.postTypeId
                    ? showPostType(postTypeList, orgInfo.postTypeId)
                    : ""
                }}</span> -->
                <span class="fontColor6" v-for=" (data, ind) in orgInfo.postTypeId" :key="ind">{{ showPostType(postTypeList, data)}},</span>
              </li>
              <li>
                <span class="fontColor6">联系方式</span>
                <span class="fontColor6">{{ orgInfo.phone }}</span>
              </li>
              <li>
                <span class="fontColor6">学历</span>
                <span class="fontColor6">{{
                  orgInfo.edu ? showEdu(orgInfo.edu) : ""
                }}</span>
              </li>
              <li>
                <span class="fontColor6">班组类型</span>
                <!-- <span class="fontColor6">{{
                  orgInfo.properties ? showTeamType(orgInfo.properties) : ""
                }}</span> -->
                <span class="fontColor6" v-for=" (info,idx) in orgInfo.properties" :key="idx">{{ showTeamType(info)}},</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="skillCertificate">
          <div class="formItem">
            <div class="imgBox">
              <span>学历证书</span>
              <img
                v-if="orgInfo.degreeCertificate"
                :src="$imgUrl(orgInfo.degreeCertificate)"
                :onerror="$global.srcImgUrl"
                alt=""
              />
              <img v-else src="@/assets/img/null.png" alt="" />
            </div>
            <div class="imgBox">
              <span>资格证书</span>
              <div v-if="orgInfo.qualificationCertificate.length > 0">
                <img
                  v-for="(item, index) in orgInfo.qualificationCertificate"
                  :key="index"
                  :src="$imgUrl(item)"
                  :onerror="$global.srcImgUrl"
                  alt=""
                />
              </div>
              <img v-else src="@/assets/img/null.png" alt="" />
            </div>
            <div class="imgBox">
              <span>职称证书</span>
              <div v-if="orgInfo.titleCertificate.length > 0">
                <img
                  v-for="(item, index) in orgInfo.titleCertificate"
                  :key="index"
                  :src="$imgUrl(item)"
                  :onerror="$global.srcImgUrl"
                  alt=""
                />
              </div>
              <img v-else src="@/assets/img/null.png" alt="" />
            </div>
            <div class="imgBox">
              <span>荣誉证书</span>
              <div v-if="orgInfo.titleHonor.length > 0">
                <img
                  v-for="(item, index) in orgInfo.titleHonor"
                  :key="index"
                  :src="$imgUrl(item)"
                  :onerror="$global.srcImgUrl"
                  alt=""
                />
              </div>
              <img v-else src="@/assets/img/null.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="essentialInformation">
        <div class="title">
          <div class="blueBgc">
            <i
              class="iconfont icon-lingdaitie2"
              style="font-size: 20px; color: #fff"
            ></i>
          </div>
          <b>成员信息</b>
          <el-divider></el-divider>
        </div>
        <div
          class="formItem"
          v-for="(item, index) in workExperienceForm.works"
          :key="index"
        >
          <div class="personalInfo">
            <b>{{ item.name }}</b>
            <ul>
              <li>
                <span class="fontColor6">性别</span>
                <span class="fontColor6">{{
                  item.sex ? (item.sex == "1" ? "男" : "女") : ""
                }}</span>
              </li>
              <li>
                <span class="fontColor6">城市</span>
                <span class="fontColor6">{{
                  item.city ? showCity(item.city) : ""
                }}</span>
              </li>
              <li>
                <span class="fontColor6">职位</span>
                <!-- <span class="fontColor6">{{
                  item.workTeam ? showPostType(postTypeList, item.workTeam) : ""
                }}</span> -->
                <span class="fontColor6" v-for=" (data, ind) in item.workTeam" :key="ind">{{ showPostType(postTypeList, data)}},</span>
              </li>
              <li>
                <span class="fontColor6">联系方式</span>
                <span class="fontColor6">{{ item.phone }}</span>
              </li>
            </ul>
          </div>
          <div class="skillCertificate">
            <div class="formItem" style="margin-left: 0px">
              <div class="imgBox">
                <span>学历证书</span>
                <img
                  v-if="item.degreeCertificate"
                  :src="$imgUrl(item.degreeCertificate)"
                  :onerror="$global.srcImgUrl"
                  alt=""
                />
                <img v-else src="@/assets/img/null.png" alt="" />
              </div>
              <div class="imgBox">
                <span>资格证书</span>
                <div v-if="item.qualificationCertificate.length > 0">
                  <img
                    v-for="(item, index) in item.qualificationCertificate"
                    :key="index"
                    :src="$imgUrl(item)"
                    :onerror="$global.srcImgUrl"
                    alt=""
                  />
                </div>
                <img v-else src="@/assets/img/null.png" alt="" />
              </div>
              <div class="imgBox">
                <span>职称证书</span>
                <div v-if="item.titleCertificate.length > 0">
                  <img
                    v-for="(item, index) in item.titleCertificate"
                    :key="index"
                    :src="$imgUrl(item)"
                    :onerror="$global.srcImgUrl"
                    alt=""
                  />
                </div>
                <img v-else src="@/assets/img/null.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="essentialInformation">
        <div class="title">
          <div class="blueBgc">
            <i
              class="iconfont icon-jiaoyu1"
              style="font-size: 20px; color: #fff"
            ></i>
          </div>
          <b>项目经历</b>
          <el-divider></el-divider>
        </div>
        <div
          class="formItem"
          v-for="(item, index) in projectInfo.infos"
          :key="index"
        >
          <div class="personalInfo">
            <ul>
              <li>
                <span class="fontColor6">项目名称</span>
                <span class="fontColor6">{{ item.title }}</span>
              </li>
              <li>
                <span class="fontColor6">公司名称</span>
                <span class="fontColor6">{{ item.company }}</span>
              </li>
              <li>
                <span class="fontColor6">工作时间</span>
                <span class="fontColor6"
                  >{{
                    item.workTime.length > 0 ? showDate(item.workTime[0]) : ""
                  }}{{ item.workTime.length > 0 ? " - " : ""
                  }}{{
                    item.workTime.length > 0 ? showDate(item.workTime[1]) : ""
                  }}</span
                >
              </li>
              <li>
                <span class="fontColor6">班组类型</span>
                <span class="fontColor6" v-for=" (info,idx) in item.typeWork" :key="idx">{{ showTeamType(info)}},</span>
              </li>
            </ul>
          </div>
          <div class="imgBox">
              <span>佐证材料</span>
              <div v-if="item.titleProve.length > 0">
                <img
                  v-for="(item, index) in item.titleProve"
                  :key="index"
                  :src="$imgUrl(item)"
                  :onerror="$global.srcImgUrl"
                  alt=""
                />
              </div>
              <img v-else src="@/assets/img/null.png" alt="" />
            </div>
        </div>
      </div>
    </div>
    <div class="empty" v-else-if="showStatus == 4">
      <img
            class="diannao"
            src="../../../img/authentication_icon_shenhezhong_normal@3x.png"
            alt=""
          />
          <span>班组信息审核中</span>
    </div>
    <div v-else-if="showStatus == 1 || showStatus == 3 || showStatus == ''">
      <el-empty description="暂无班组信息"></el-empty>
    </div>

  </div>
</template>

<script>
import { detailTeam } from "@/api/team/team";
import { getProvincial, getCity } from "@/api/index/index";
import { jobPostType } from "@/api/position/position";
import moment from "moment";
export default {
  data() {
    return {
      postTypeList: [], //职位类型列表
      city: [],
      up: false,
      show: 1,
      showStatus: "",
      name: "",
      dictData: JSON.parse(localStorage.getItem("dict")),
      team_type: [],
      job_gm: [],
      sex: [],
      jobEdu: [],
      orgInfo: {
        jobUserId: "",
        sex: "",
        edu: "",
        postTypeId: "",
        name: "",
        city: "",
        cityName: "",
        orgCode: "",
        properties: "",
        identityCode: "",
        phone: "",
        desc: "",
        identityCode: "", //身份证号
        identityCard1: "", //身份证正面
        identityCard0: "", //身份证背面
        degreeCertificate: "", //学历证书
        qualificationCertificate: [], //资格证书
        titleCertificate: [], //职称证书
        pow: "", //工作证明
      },
      // 成员信息
      workExperienceForm: {
        works: [
          {
            itemType: 1,
            name: "",
            sex: "",
            workTeam: "",
            idCode: "",
            phone: null,
            desc: null,
            city: "",
            cityName: "",
            cityList: [],
            identityCard1: "", //身份证正面
            identityCard0: "", //身份证背面
            degreeCertificate: "", //学历证书
            qualificationCertificate: [], //资格证书
            titleCertificate: [], //职称证书
            pow: "", //工作证明
          },
        ],
      },
      projectInfo: {
        infos: [
          {
            itemType: 2,
            title: "",
            company: "",
            workTime: [],
            typeWork: "",
            titleProve:[]
          },
        ],
      },
      provincialList: [], //省份列表
      cityList: [], //城市列表
    };
  },
  created() {
    this.cityList = JSON.parse(localStorage.getItem("cityList"));
    this.orgInfo.jobUserId = localStorage.getItem("userId");
    this._getProvincial();
    this.getJobPostType();
    this.detailTeamInfo();
    this.team_type = this.dictData.find((e) => e.code === "team_type").children;
    // 性别
    this.sex = this.dictData.filter((i) => i.code == "sex")[0].children;
    // 学历
    this.jobEdu = this.dictData.filter((i) => i.code == "job_edu")[0].children;
    // 工作类型
    this.job_gm = this.dictData.find((e) => e.code === "job_gm").children;
  },
  methods: {
    editInfo() {
      this.$router.push({ path: "editTeamInfo" });
    },
    //日期格式化
    showDate(val) {
      return moment(val).format("YYYY-MM-DD");
    },
    // 显示学历
    showEdu(val) {
      return this.jobEdu.filter((i) => i.code == val)[0].name;
    },
    // 显示班组类型
    showTeamType(val) {
      if(val){
        return this.team_type.filter((i) => i.code == val)[0].name;
      }else{
        return ''
      }
    },
    changeCascader(key, arrData) {
      let arr = [];
      let returnArr = []; // 存放结果的数组
      let depth = 0; // 定义全局层级
      // 定义递归函数
      function childrenEach(childrenData, depthN) {
        for (var j = 0; j < childrenData.length; j++) {
          depth = depthN; // 将执行的层级赋值 到 全局层级
          arr[depthN] = childrenData[j].id;
          if (childrenData[j].id == key) {
            returnArr = arr.slice(0, depthN + 1); //将目前匹配的数组，截断并保存到结果数组，
            break;
          } else {
            if (childrenData[j].children) {
              depth++;
              childrenEach(childrenData[j].children, depth);
            }
          }
        }
        return returnArr;
      }
      return childrenEach(arrData, depth);
    },
    // 显示城市
    showCity(val) {
      let city = this.cityList.filter((i) => i.CITY_CODE == val)[0].SHORT_NAME;
      let descId = this.cityList.filter((i) => i.CITY_CODE == val)[0]
        .PROVINCE_CODE;
      let desc = this.provincialList.filter((i) => i.PROVINCE_CODE == descId)[0]
        .SHORT_NAME;
      return desc + "·" + city;
    },
    // 显示职位
    showPostType(list, id) {
      var title = "";
      for (let i = 0; i < list.length; i++) {
        if (list[i].id == id) {
          title = list[i].title;
          break;
        } else if (list[i].children && list[i].children.length > 0) {
          title = this.showPostType(list[i].children, id);
          if (title) return title;
        }
      }
      return title;
    },
    // 获取班组认证信息
    detailTeamInfo() {
      let jobUserId = localStorage.getItem("userId");
      detailTeam({ jobUserId }).then((res) => {
        console.log(res, "信息");
        // 如果没有班组信息，在第一步
        if (res.data == null) {
          this.showStatus = "";
        }
        // 查得到班组信息，查不到认证信息，在第4步
        if (res.data) {
          if (res.data.auditStatus == 2 || res.data.auditStatus == 4) {
            this.infoDetail(res);
          }
          this.showStatus = res.data.auditStatus;
        }
      });
    },
    // 回显数据信息
    infoDetail(res) {
      this.orgInfo.desc = this.cityList.filter(
        (i) => i.CITY_CODE == res.data.city
      )[0].PROVINCE_CODE;
      this.orgInfo.city = res.data.city;
      this.orgInfo.name = res.data.name;
      this.orgInfo.orgCode = res.data.num;
      this.orgInfo.sex = res.data.sex;
      this.orgInfo.postTypeId = res.data.postType.split(",");
      this.orgInfo.properties = res.data.teamType.split(",");
      this.orgInfo.identityCode = res.data.idCard;
      this.orgInfo.phone = res.data.phone;
      this.orgInfo.edu = res.data.edu;
      this.orgInfo.identityCard1 = res.data.identityCard1;
      this.orgInfo.identityCard0 = res.data.identityCard0;
      this.orgInfo.degreeCertificate = res.data.degreeCertificate;
      this.orgInfo.qualificationCertificate = res.data.qualificationCertificate ? res.data.qualificationCertificate.split(",") : [];
      this.orgInfo.titleCertificate = res.data.titleCertificate ? res.data.titleCertificate.split(",") : [];
      this.orgInfo.titleHonor = res.data.img ? res.data.img.split(",") : [];
      this.orgInfo.pow = res.data.pow;
      this.orgInfo.id = res.data.id;
      let data = [];
      res.data.users.map((item, index) => {
        let obj = {
          qualificationCertificate: [],
          titleCertificate: [],
        };
        obj.name = item.name;
        obj.sex = item.sex;
        obj.workTeam = item.postType.split(",");
        obj.city = item.city;
        obj.desc = item.province;
        obj.idCode = item.idCard;
        obj.phone = item.phone;
        obj.identityCard1 = item.identityCard1;
        obj.identityCard0 = item.identityCard0;
        obj.degreeCertificate = item.degreeCertificate;
        obj.qualificationCertificate = item.qualificationCertificate
          ? item.qualificationCertificate.split(",")
          : [];
        obj.titleCertificate = item.qualificationCertificate
          ? item.titleCertificate.split(",")
          : [];
        obj.pow = item.pow;
        obj.id = item.id;
        getCity({ PROVINCE_CODE: obj.desc }).then((res) => {
          this.workExperienceForm.works[index].cityList = res.data;
        });
        data.push(obj);
        return item;
      });
      this.workExperienceForm.works = data;
      let data1 = [];
      res.data.projects.map((item, index) => {
        let obj = {
          workTime: [],
        };
        obj.company = item.company;
        obj.title = item.project;
        if(item.startTime || item.endTime){
          obj.workTime[0] = item.startTime ? item.startTime : '';
        obj.workTime[1] = item.endTime ? item.endTime : '';
        }
        obj.typeWork = item.teamType.split(",");
        obj.titleProve = item.img ? item.img.split(",") : [];
        obj.id = item.id;
        data1.push(obj);
        return item;
      });
      this.projectInfo.infos = data1;
      console.log(this.projectInfo.infos)
    },

    // 职位类型
    getJobPostType() {
      jobPostType({ code: "post_type" }).then((res) => {
        this.postTypeList = this.hasChildren(res.data[0].children);
      });
    },
    // 查询省份
    _getProvincial() {
      getProvincial().then((res) => {
        this.provincialList = res.data;
      });
    },
    // 查询城市
    _getCity(code) {
      getCity({ PROVINCE_CODE: code }).then((res) => {
        this.cityList = res.data;
        console.log(this.cityList);
      });
    },
    hasChildren(list) {
      list.forEach((item) => {
        if (item.children.length > 0) {
          this.hasChildren(item.children);
        } else {
          item.children = null;
        }
      });
      return list;
    },
  },
};
</script>

<style scoped lang="scss">
@import "./scss/index.scss";
.posd {
  width: 400px;
  height: 400px;
  display: block;
}
.footaa {
  margin-top: 207px;
}
.banner {
  width: 100%;
  background: url(../../../img/beitong.png) repeat;
  background-size: 100% 100%;
}
.banner-img {
  display: block;
  margin: 0 auto;
  width: 1200px;
  height: 250px;
  display: flex;
  justify-content: space-around;
  img {
    width: 419px;
    height: 250px;
  }
}
.banner-zi {
  margin-top: 82px;
  width: 503px;
  height: 124px;
  display: block;
  font-size: 48px;
  color: #3584ab;
}

.mapBox {
  .amap-box {
    height: 400px;
  }
}
</style>
